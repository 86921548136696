import React, { Component } from 'react';

import { withSnackbar } from 'notistack';

import Paper from '@material-ui/core/Paper';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { withStyles } from '@material-ui/core/styles';

import { Storage, Auth } from 'aws-amplify';
import Pica from 'pica';

import formatError from '../../formatError/formatError';

Object.defineProperty(Array.prototype, 'unique', {
  enumerable: false,
  configurable: false,
  writable: false,
  value: function() {
      var a = this.concat();
      for(var i=0; i<a.length; ++i) {
          for(var j=i+1; j<a.length; ++j) {
              if(a[i] === a[j])
                  a.splice(j--, 1);
          }
      }

      return a;
  }
});

const styles = theme => ({
  dropZone: {
    padding: theme.spacing.unit * 3,
  },
  dropInfo: {
    textAlign: 'center'
  },
  gridList: {

  },
  uploadButton: {
    marginTop: theme.spacing.unit 
  },
  input: {
    fontSize: 14,
    margin: 'auto',
    display: 'block'
  }
});

class PhotoUpload extends Component {

  constructor(props) {
    super(props);

    this.state = {
      files: [],
      previews: [],
      currentNumberOfPhotos: 0,
      desiredNumberOfPhotos: 0,
      uploading: false,
      currentUploadProgress: 0,
      uploadCompleted: false
    }
  }

  hasValidFileType = type => {
    return type.startsWith('image') && ( type.endsWith('png') || type.endsWith('jpg') || type.endsWith('jpeg'))
  }

  dropHandler = (ev) => { 
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();

    let desiredNumberOfPhotos = 0;
  
    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < ev.dataTransfer.items.length; i++) {
        // If dropped items aren't files/images, reject them
        if (ev.dataTransfer.items[i].kind === 'file' && this.hasValidFileType(ev.dataTransfer.items[i].type)) {
          this.setState({
            desiredNumberOfPhotos: ++desiredNumberOfPhotos
          })
          let file = ev.dataTransfer.items[i].getAsFile();
          this.readPhoto(file);
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (let i = 0; i < ev.dataTransfer.files.length; i++) {
        let file = ev.dataTransfer.files[i];
        if(file && this.hasValidFileType(file.type)) { // allow only images
          this.setState({
            desiredNumberOfPhotos: ++desiredNumberOfPhotos
          })
          this.readPhoto(file);
        }
      }
    }
    
    // Pass event to removeDragData for cleanup
    this.removeDragData(ev);
  }

  inputHandler = (ev) => {
    let desiredNumberOfPhotos = 0;

    for (let i = 0; i < ev.target.files.length; i++) {
      let file = ev.target.files[i];
      if(file && this.hasValidFileType(file.type)) { // allow only images
        this.setState({
          desiredNumberOfPhotos: ++desiredNumberOfPhotos
        })
        this.readPhoto(file);
      }
    }
  }

  dragOverHandler = (ev) => { 
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
  }

  removeDragData = (ev) => { 
    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to remove the drag data
      ev.dataTransfer.items.clear();
    } else {
      // Use DataTransfer interface to remove the drag data
      ev.dataTransfer.clearData();
    }
  }

  readPhoto = (file) => {
    const readPhotoWorker = new Worker('./readPhotoWorker.js');

    readPhotoWorker.onmessage = (message) => {
      const readResult = message.data;

      this.setState(prevState => ({
        previews: prevState.previews.concat(readResult).unique(),
        files: prevState.files.concat(file).unique(),
        currentNumberOfPhotos: (prevState.currentNumberOfPhotos+1)
      }));
    };
    
    readPhotoWorker.postMessage(file);
  }

  delete = (position) => () => {   
    this.setState(prevState => {
      prevState.files.splice(position, 1);
      prevState.previews.splice(position, 1);

      return {
        files: prevState.files,
        previews: prevState.previews
      }
    });
  }

  registerCognitoIdentityId() {
    Auth.currentCredentials()
      .then(credentials => {
        Storage.put('photos/users/'+credentials.identityId, '', {
          level: 'public',
          contentType: 'plain/text'
        })
        .then (result => {
          // console.log(result);
        })
        .catch(err => this.props.enqueueSnackbar(formatError(err), { variant: 'error' }));
      })
      .catch(err => this.props.enqueueSnackbar(formatError(err), { variant: 'error' }));
  }

  upload = () => {
    this.setState({
      uploading: true
    })

    this.registerCognitoIdentityId();

    this.state.files.forEach((file, index) => {
      Storage.put('photos/original/'+file.name, file, {
        level: 'protected',
        contentType: file.type
      })
      .then(result => {
        this.createThumbnail(document.getElementById('image'+index), 1024)
        .then(blob => {
          Storage.put('photos/thumbnail/'+file.name, blob, {
            level: 'protected',
            contentType: 'image/jpeg'
          })
          .then (result => {          
            this.setState(prevState => ({
              currentUploadProgress: (prevState.currentUploadProgress+1)
            }));
          })
          .catch(err => this.props.enqueueSnackbar(formatError(err), { variant: 'error' }));
        })
        .catch(err => this.props.enqueueSnackbar(formatError(err), { variant: 'error' }));
      })
      .catch(err => this.props.enqueueSnackbar(formatError(err), { variant: 'error' }));
    });
  }

  createThumbnail = (original, width, type) => {
    var canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = original.height * width / original.width;

    const pica = Pica();
    return pica.resize(original, canvas)
      .then(result => pica.toBlob(result, 'image/jpeg', 0.90))
      .catch(err => this.props.enqueueSnackbar(formatError(err), { variant: 'error' }));
  }

  componentDidUpdate() {
    if(this.state.uploading && this.state.currentUploadProgress === this.state.previews.length) {
      this.setState({
        uploading: false,
        previews: [],
        files: [],
        uploadCompleted: true,
        currentNumberOfPhotos: 0,
        desiredNumberOfPhotos: 0,
        currentUploadProgress: 0
      });
    }
  }

  render() {
    return (
      <div>
        <Paper onDrop={this.dropHandler} onDragOver={this.dragOverHandler} className={this.props.classes.dropZone}>
          {this.state.uploadCompleted && this.state.files.length === 0 &&
            <p className={this.props.classes.dropInfo}>Die Fotos wurden geuploaded.</p>
          }
          {this.state.files.length === 0 &&
            <div>
              <p className={this.props.classes.dropInfo}>Ein oder mehrere Foto(s) in diese Ablagezone ziehen.</p>
              <p className={this.props.classes.dropInfo}>Alternativ kann (z.B. auf Smartphones) die folgende Upload-Funktion verwendet werden:</p>
              <input id="file" type="file" accept="image/*" multiple className={this.props.classes.input} onChange={this.inputHandler} />
              <p className={this.props.classes.dropInfo}>Erlaubte Formate: .jpg, .jpeg, .png</p>
            </div>
          }
          
          <GridList className={this.props.classes.gridList} cols={3}>
            {this.state.previews.map((preview, position) => (
              <GridListTile key={position}>
                <img src={preview} alt="Preview" id={'image'+position} />
                <GridListTileBar
                  title={<Button fullWidth color="primary" onClick={this.delete(position)}>delete</Button>}
                />
              </GridListTile>
            ))}
          </GridList>
          
          {this.state.files.length > 0 && this.state.desiredNumberOfPhotos === this.state.currentNumberOfPhotos &&
            <div>
              <p className={this.props.classes.dropInfo}>Weitere(s) Foto(s) in diese Ablagezone ziehen oder...</p>
              <Button fullWidth color="primary" variant="contained" className={this.props.classes.uploadButton} onClick={this.upload}>Fotos uploaden</Button>
            </div>
          }
        </Paper>

        <Dialog open={this.state.desiredNumberOfPhotos !== this.state.currentNumberOfPhotos}>
          <DialogContent>
            <DialogContentText>Fotos werden in Ablagezone geladen...</DialogContentText>
            <LinearProgress variant="indeterminate" /* value={this.state.currentNumberOfPhotos/this.state.desiredNumberOfPhotos*100} */ />
          </DialogContent>
        </Dialog>

        <Dialog open={this.state.uploading}>
          <DialogContent>
            <DialogContentText>Fotos werden geuploadet...</DialogContentText>
            <LinearProgress variant="indeterminate" /* value={this.state.currentUploadProgress/this.state.previews.length*100} */ />
          </DialogContent>
        </Dialog>
      </div>
    )
  }

}

export default withSnackbar(withStyles(styles)(PhotoUpload));