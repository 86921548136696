import React, {Component} from 'react';

import { withStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import Close from '@material-ui/icons/Close';
import SaveAlt from '@material-ui/icons/SaveAlt';

import { Storage } from 'aws-amplify';

import { withSnackbar } from 'notistack';

import formatError from '../../formatError/formatError';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: 'inherit',
  },
  gridList: {
    maxWidth: 1000
  },
  imgbox: {
    display: 'grid',
    height: '100%'
  },
  photo: {
    maxWidth: '100%',
    maxHeight: '100vh',
    margin: 'auto'
  }
});

class Photos extends Component {

  constructor(props) {
    super(props);

    this.state = {
      thumbnails: [],
      photoNames: [],
      identityId: [],
      photo: '',
      currentPhotoIndex: ''
    }
  }

  componentDidMount() {
    Storage.list('photos/users', {
      level: 'public'
    })
      .then(users => {
        users.forEach(user => {
          let identityId = user.key.split("/")[2];

          this.loadUserPhotos(identityId);
        });
      })
      .catch(err => this.props.enqueueSnackbar(formatError(err), { variant: 'error' }));
  }

  loadUserPhotos(identityId) {
    Storage.list('photos/thumbnail/', {
      level: 'protected',
      identityId: identityId
    })
      .then(result => {
        result.forEach(thumbnail => {
          Storage.get(thumbnail.key, {
            level: 'protected',
            identityId: identityId
          })
            .then(thumbnailURL => {
              this.setState(prevState => ({
                thumbnails: prevState.thumbnails.concat(thumbnailURL),
                photoNames: prevState.photoNames.concat(thumbnail.key.split('/').pop()),
                identityId: prevState.identityId.concat(identityId),
              }));
            })
            .catch(err => this.props.enqueueSnackbar(formatError(err), { variant: 'error' }));
        });
      })
      .catch(err => this.props.enqueueSnackbar(formatError(err), { variant: 'error' }));
  }

  openPhoto = (index) => () => {
    this.setState({
      currentPhotoIndex: index,
      photo: ''
    });

    Storage.get('photos/original/'+this.state.photoNames[index], {
      level: 'protected',
      identityId: this.state.identityId[index]
    })
      .then(photoURL => {        
        this.setState({
          photo: photoURL
        });
      })
      .catch(err => this.props.enqueueSnackbar(formatError(err), { variant: 'error' }));  
  }

  handleClose = () => {
    this.setState({
      photo: ''
    });
  }
  
  render() {
    return (
      <div className={this.props.classes.root}>
        {this.state.thumbnails.length > 0 &&
          <GridList className={this.props.classes.gridList} cols={3}>
            {this.state.thumbnails.map((thumbnail, index) => (
              <GridListTile key={index}>
                <img src={thumbnail} alt='' onClick={this.openPhoto(index)} style={{cursor: 'pointer'}} />
              </GridListTile>
            ))}
          </GridList>
        }
        <Dialog
          fullScreen
          open={Boolean(this.state.photo)}
          onClose={this.handleClose}
        >
          <DialogContent className={this.props.classes.imgbox}>
            <img src={this.state.thumbnails[this.state.currentPhotoIndex]} alt='' className={this.props.classes.photo} />
          </DialogContent>
          <DialogActions>
            <IconButton disabled={!(this.state.currentPhotoIndex > 0)} onClick={this.openPhoto(this.state.currentPhotoIndex-1)} color="primary">
              <KeyboardArrowLeft />
            </IconButton >
            <IconButton disabled={!(this.state.currentPhotoIndex < this.state.thumbnails.length-1)} onClick={this.openPhoto(this.state.currentPhotoIndex+1)} color="primary">
              <KeyboardArrowRight />
            </IconButton>
            <IconButton target="_blank" href={this.state.photo} color="primary">
              <SaveAlt />
            </IconButton>
            <IconButton onClick={this.handleClose} color="primary">
              <Close />
            </IconButton>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withSnackbar(withStyles(styles)(Photos));