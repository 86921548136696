import React, { Component } from 'react';

import {
  BrowserRouter,
  Route,
  Switch
} from "react-router-dom";

import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import theme from './theme.js';

import './App.css';

import NotFound from './notfound/NotFound.js';
import Login from './login/Login.js';
import Landing from './private/landing/Landing.js';
import Photos from './private/photos/Photos.js';
import PhotoUpload from './private/photoUpload/PhotoUpload.js';
import TopBar from './private/topBar/TopBar.js'

import Amplify from 'aws-amplify';
import { Auth } from 'aws-amplify';
import aws_exports from './aws-exports';
Amplify.configure(aws_exports);

const LoggedInRoutes = [
  <Route key='landingRoute' exact path="/" component={Landing} />,
  <Route key='photosRoute' path="/photos" component={Photos} />,
  <Route key='photoUploadRoute' path="/photoUpload" component={PhotoUpload} />,
];

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loggedInUser: null,
      autoLoginAttempted: false,
    };
  }

  loginUser = (user) => {
    this.setState({loggedInUser: user});
  }

  logoutUser = (user) => {
    this.setState({loggedInUser: user});
  }

  componentDidMount() {
    Auth.currentAuthenticatedUser()
      .then(user => {
        this.setState({
          loggedInUser: user,
          autoLoginAttempted: true
        });
      })
      .catch(err => {
        this.setState({
          autoLoginAttempted: true
        });
      });
  }

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <div className="app">
          {this.state.loggedInUser!=null &&
            <BrowserRouter>
              <div>
                <TopBar user={this.state.loggedInUser} logout={this.logoutUser}></TopBar>
                <div className="content">
                  <Switch>
                    {[
                      LoggedInRoutes,
                      <Route key='notFound' component={NotFound} />,
                    ]}
                  </Switch>
                </div>
              </div>
            </BrowserRouter>
          }
          {this.state.loggedInUser==null && this.state.autoLoginAttempted &&
            <div className="login">
              <Login loggedInCallback={this.loginUser}></Login>
            </div>
          }
        </div>
      </MuiThemeProvider>
    );
  }
}

export default App;