import React, {Component} from 'react';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountCircle from '@material-ui/icons/AccountCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

import { Auth } from 'aws-amplify';

import LoginError from './LoginError';

const styles = theme => ({
  layout: {
    width: 'auto',
    display: 'block',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: '250px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  textField: {
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  button: {
    marginTop: theme.spacing.unit*2,
  },
  accountIcon: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit*1,
    transform: 'scale(1.8)',
  },
  progress: {
    marginLeft: theme.spacing.unit,
    transform: 'scale(0.5)',
  }
});

class NewPasswordRequired extends Component {

  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      loginError: '',
      inProgress: false,
      newPasswordRequired: false
    }
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleSubmit = () => {
    this.updatePassword();
  }

  updatePassword = () => {
    this.setState({inProgress: true});

    Auth.completeNewPassword(this.props.user, this.state.password, [])
      .then(user => {
        this.setState({
          loginError: '',
          inProgress: false
        });

        this.props.loggedInCallback(user);
      })
      .catch(err => {
        this.setState({
          loginError: err.message,
          inProgress: false
        });
      });
  }

  render() {
    const loginError = this.state.loginError;
    const inProgress = this.state.inProgress;
    return (
      <div className={this.props.classes.layout}>
        <Paper className={this.props.classes.paper}>
          <AccountCircle color="primary" className={this.props.classes.accountIcon} />
          { loginError &&
            <LoginError message={loginError}></LoginError>
          }
          <ValidatorForm
            ref="newPasswordRequiredForm"
            onSubmit={this.handleSubmit}
            onError={errors => console.log(errors)}
            className={this.props.classes.container}
          >
            <TextValidator
              fullWidth
              id="password"
              label="Passwort"
              name="password"
              type="password"
              className={this.props.classes.textField}
              margin="normal"
              value={this.state.password}
              onChange={this.handleChange('password')}
              validators={['required', 'minStringLength:8']}
              errorMessages={['bitte ausfüllen', 'zu kurz']}
            />
            <Button fullWidth variant="outlined" color="primary" type="submit" className={this.props.classes.button}>
              Neues Passwort setzen
              <ExitToAppIcon className={this.props.classes.rightIcon} />
              { inProgress &&
                <CircularProgress className={this.props.classes.progress}/>
              }
            </Button>
          </ValidatorForm>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(NewPasswordRequired);