import { createMuiTheme } from '@material-ui/core/styles';

const Husk = '#B39D5A'; // gold
const PaleGold = '#E3C28D'; // light gold
const Iron = '#D6D6D6'; // light silver
const Aluminum = '#9B9B9B'; // dark silver
const White = '#FFFFFF';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      light: PaleGold,
      main: Husk,
      //dark: Charcoal,
      contrastText: White,
    },
    secondary: {
      light: Iron,
      main: Aluminum,
      // dark: will be calculated from palette.secondary.main,
      contrastText: White,
    },
    // error: will use the default color
  },
});

export default theme;