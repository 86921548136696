import React, { Component } from 'react';
//import ReactDOM from 'react-dom';

import { Storage } from 'aws-amplify';
import axios from 'axios';

import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

import { withSnackbar } from 'notistack';

import formatError from '../../formatError/formatError';

const styles = theme => ({
  paper: {
    padding: theme.spacing.unit * 3,
    maxWidth: 600
  }
});

class Landing extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      extHTML: ''
    }
  }

  componentDidMount() {
    Storage.get('landing/landing.html', {
      level: 'protected',
      identityId: 'eu-central-1:94389cd6-f864-4eb7-b2b4-172d94b5bbfa'
    })
      .then(url => {
        axios.get(url).then(
          response => {
            this.setState({
              extHTML: response.data,
              loading: false
            });
          }, err => {
            this.props.enqueueSnackbar(formatError(err), { variant: 'error' });
          }
        );
      })
      .catch(err => this.props.enqueueSnackbar(formatError(err), { variant: 'error' }));
  }

  createMarkup = () => { 
    return {__html: this.state.extHTML};
  }
  
  render() {
    return (
      <div>
        { this.state.loading &&
          <CircularProgress className={this.props.classes.progress}/>
        }

        { !this.state.loading &&
        <Paper className={this.props.classes.paper}>
          <div dangerouslySetInnerHTML={this.createMarkup()}>
          </div>
        </Paper>
        }
      </div>
    );
  }
}

export default withSnackbar(withStyles(styles)(Landing));