import React from 'react';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  error: {
    color: 'red',
    fontSize: '12pt',
    marginTop: theme.spacing.unit
  }
});

function ChangePasswordError(props) {
  return (
    <div className={props.classes.error}>
      {props.message}
    </div>
  );
}

export default withStyles(styles)(ChangePasswordError);