import React, {Component} from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

import { Auth } from 'aws-amplify';

import ChangePasswordError from './ChangePasswordError';

const styles = theme => ({
  textField: {
  },
  progress: {
    marginLeft: theme.spacing.unit,
    transform: 'scale(0.5)',
  },
  button: {
    marginTop: theme.spacing.unit*2,
  },
});

class ChangePasswordDialog extends Component {

  constructor(props) {
    super(props);
    this.state = {
      oldPassword: '',
      newPassword: '',
      error: '',
      inProgress: false,
      success: false
    }
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleSubmit = () => {
    this.setState({inProgress: true});

    Auth.currentAuthenticatedUser()
      .then(user => {
          return Auth.changePassword(user, this.state.oldPassword, this.state.newPassword);
      })
        .then(data => {
          this.setState({
            inProgress: false,
            success: true
          });
        })
        .catch(err => {
          this.setState({
            error: err.message,
            inProgress: false
          });
        });
  }

  handleClose = () => {
    this.setState({
      oldPassword: '',
      newPassword: '',
      error: '',
      inProgress: false,
      success: false
    });

    this.props.closeDialogClicked();
  };

  render() {
    const inProgress = this.state.inProgress;
    return (
      <div>

        <Dialog
        open={this.props.open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Passwort ändern</DialogTitle>
          <DialogContent>
            <DialogContentText>
              { this.state.error &&
                <ChangePasswordError message={this.state.error}></ChangePasswordError>
              }

              { this.state.success &&
                <div>Passwort wurde erfolgreich geändert.</div>
              }
            </DialogContentText>

            {!this.state.success &&
              <ValidatorForm
                ref="changePasswordForm"
                onSubmit={this.handleSubmit}
                onError={errors => console.log(errors)}
              >
                <TextValidator
                  id="oldPassword"
                  label="Altes Passwort"
                  name="oldPassword"
                  type="password"
                  className={this.props.classes.textField}
                  margin="normal"
                  value={this.state.oldPassword}
                  onChange={this.handleChange('oldPassword')}
                  validators={['required', 'minStringLength:8']}
                  errorMessages={['bitte ausfüllen', 'zu kurz']}
                  fullWidth
                />
                <TextValidator
                  id="newPassword"
                  label="Neues Passwort"
                  name="newPassword"
                  type="password"
                  className={this.props.classes.textField}
                  margin="normal"
                  value={this.state.newPassword}
                  onChange={this.handleChange('newPassword')}
                  validators={['required', 'minStringLength:8']}
                  errorMessages={['bitte ausfüllen', 'zu kurz']}
                  fullWidth
                />
                <Button fullWidth className={this.props.classes.button} color="primary" type="submit">
                  Passwort ändern
                  { inProgress &&
                    <CircularProgress className={this.props.classes.progress}/>
                  }
                </Button>
              </ValidatorForm>
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Schließen
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(ChangePasswordDialog);