import React, {Component} from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    button: {
      marginTop: theme.spacing.unit*2,
    }
  });

class Impressum extends Component {

    state = {
        show: false
    }

    toggleImpressum = () => {
        this.setState({
            show: !this.state.show
        })
    }

    render() {
        return (
            <div>
                <Button fullWidth variant="outlined" color="primary" className={this.props.classes.button} onClick={this.toggleImpressum}>
                    Impressum
                </Button>
                <Dialog open={this.state.show} onClose={this.toggleImpressum}>
                    <DialogTitle>Impressum</DialogTitle>
                    <DialogContent>
                        <DialogContentText><p>Christoph & Gerlinde Kaineder</p><p>A-1190 Wien</p></DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.toggleImpressum} color="primary">
                        Schließen
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(Impressum);