import React, {Component} from 'react';

import Button from '@material-ui/core/Button';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core/styles';

import { Auth } from 'aws-amplify';

import ChangePasswordDialog from './ChangePasswordDialog';
import formatError from '../../formatError/formatError';

import { withSnackbar } from 'notistack';

const styles = {
  root: {
    flexGrow: 1,
  },
  accountCircle: {
    marginLeft: 10
  },
};

class UserMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      auth: true,
      anchorEl: null,
      passwordChangeDialogOpen: false
    };
  }

  handleChange = event => {
    this.setState({ auth: event.target.checked });
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  logout = () => {
    Auth.signOut()
      .then(data => this.props.logout(data))
      .catch(err => this.props.enqueueSnackbar(formatError(err), { variant: 'error' }));
  }

  handlePasswordChangeClicked = () => {
    this.handleClose();

    this.setState({
      passwordChangeDialogOpen: true
    });
  }

  closeChangePasswordDialog = () => {
    this.setState({
      passwordChangeDialogOpen: false
    }); 
  }

  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div>
        <Button
          aria-owns={open ? 'menu-appbar' : undefined}
          aria-haspopup="true"
          onClick={this.handleMenu}
          color="inherit"
        >
          <ArrowDropDown />
          {this.props.user.username}
          <AccountCircle className={this.props.classes.accountCircle} />
          
        </Button>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={open}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.handlePasswordChangeClicked}>Passwort ändern</MenuItem>
          <MenuItem onClick={this.logout}>Ausloggen</MenuItem>
        </Menu>

        <ChangePasswordDialog open={this.state.passwordChangeDialogOpen} closeDialogClicked={this.closeChangePasswordDialog}></ChangePasswordDialog>
      </div>
    );
  }
}

export default withSnackbar(withStyles(styles)(UserMenu));