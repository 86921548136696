const standardErrorMessage = 'Es ist ein Fehler aufgetreten';

export default function formatError(err) {
    if(err instanceof Object) {
        if(err.message) {
            return standardErrorMessage+': '+err.message;
        } else {
            return standardErrorMessage+': '+JSON.stringify(err);
        }
    } else if(typeof err === 'string') {
        return standardErrorMessage+': '+err;
    } else {
        return standardErrorMessage+'.';
    }
}