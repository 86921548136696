import React, {Component} from 'react';

import {Link} from "react-router-dom";

import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -18,
    marginRight: 10,
  },
};

class MainMenu extends Component {

  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: false,
      userMenuAnchorEl: null
    }
  }

  toggleDrawer = (value) => () => {
    this.setState({
      drawerOpen: value
    });
  }

  render() {
    return (
      <div>
        <IconButton onClick={this.toggleDrawer(true)} className={this.props.classes.menuButton} color="inherit" aria-label="Menu">
          <MenuIcon />
        </IconButton>
        <Drawer open={this.state.drawerOpen} anchor="left" onClose={this.toggleDrawer(false)}>
          <List>
            <ListItem onClick={this.toggleDrawer(false)} button divider>
              <ListItemIcon><CloseIcon /></ListItemIcon>
              <ListItemText primary="Menü schließen" />
            </ListItem>
            <ListItem component={Link} to="/" onClick={this.toggleDrawer(false)} button divider>
              <ListItemIcon><InfoIcon /></ListItemIcon>
              <ListItemText primary="Informationen zur Hochzeit" />
            </ListItem>
            <ListItem component={Link} to="/photoUpload" onClick={this.toggleDrawer(false)} button divider>
              <ListItemIcon><AddAPhotoIcon /></ListItemIcon>
              <ListItemText primary="Eigene Hochzeitfotos uploaden" />
            </ListItem>
            <ListItem component={Link} to="/photos" onClick={this.toggleDrawer(false)} button divider>
              <ListItemIcon><CameraAltIcon /></ListItemIcon>
              <ListItemText primary="Hochzeitfotos ansehen" />
            </ListItem>
          </List>
        </Drawer>
    </div>
    );
  }
}

export default withStyles(styles)(MainMenu);