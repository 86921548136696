// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile =  {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:2e99ffb8-89ac-48e4-85ca-90ae96224e5c",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_s1blgesHq",
    "aws_user_pools_web_client_id": "7j7oc72bva89pont2bip1b47qp",
    "aws_user_files_s3_bucket": "weddingstorage",
    "aws_user_files_s3_bucket_region": "eu-central-1",
    "aws_content_delivery_bucket": "weddinghosting",
    "aws_content_delivery_bucket_region": "eu-central-1",
    "aws_content_delivery_url": "https://d5olyhxwuvpu1.cloudfront.net"
};


export default awsmobile;
