import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';

import MainMenu from "./MainMenu"
import UserMenu from "./UserMenu"

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  }
};

function TopBar(props) {
  return (
    <div className={props.root}>
    <AppBar position="fixed" color="primary">
      <Toolbar>
        <MainMenu></MainMenu>
        <div className={props.classes.grow} />
        <UserMenu user={props.user} logout={props.logout}></UserMenu>
      </Toolbar>
    </AppBar>
  </div>
  );
}

export default withStyles(styles)(TopBar);